import React from "react"
import PageRoot from "../components/PageRoot"
import { graphql, Link } from "gatsby"
import { renderCarouselItem } from "../utils"
import { useTranslation } from "react-i18next"
import ContainerWithPadding from "../components/ContainerWithPadding"
import EventureGrid from "../components/EventureGrid"
import { Button, Col, Row } from "antd"
import "../components/GenericHeader.less"
import wallpaper from "../../static/assets/images/hexagon-bg.jpg"
import GenericHeader from "../components/GenericHeader"

const OnDemandPage = ({ data }) => {
  const { t } = useTranslation()
  const { videos, courses, categories } = data.eventure

  const headerData = {
    title: t("title:heroOnDemandTitle1"),
    subtitle: t("title:heroOnDemandSubtitle"),
    description: t("title:heroOnDemandDescription"),
    wallpaper: "/assets/images/wallpaper-on-demand.jpeg",
    actions: []
  }

  return (
    <PageRoot title={t("label:onDemand")}>
      <GenericHeader
        data={{
          title: headerData.title,
          subtitle: headerData.description,
          wallpaper: wallpaper
        }}
      />
      <ContainerWithPadding size="large" style={{ paddingTop: 0 }}>
        <EventureGrid
          isVertical={true}
          colSettings={{
            xs: 12,
            sm: 12,
            md: 8,
            lg: 6
          }}
          title={null}
          dataSource={courses}
          renderItem={renderCarouselItem}
        />
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query onDemandData {
    eventure {
      courses(
        order_by: { created_at: desc }
        where: { state: { _eq: PUBLISHED }, price_policy: { _eq: ON_DEMAND } }
      ) {
        ...PreviewCardCourse
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
        special_price {
          final_price
          start_at
          end_at
        }
      }
      categories {
        ...CategoryWithOnDemandContent
      }
    }
  }
`

export default OnDemandPage
